@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css?family=Raleway&display=swap");

body {
  height: 100%;
}

#root {
  height: 100%;
}

.back-blur {
  background: rgba(255, 255, 255, 0.1);
}

.white-back {
  background: linear-gradient(180deg, #effffd 0%, #ffffff 47.92%, #eff7ff 100%);
}

.h-500 {
  height: 500px;
}

.blue-back {
  background: linear-gradient(180deg, #effffd 0%, #ffffff 47.92%, #effffd 100%);
}

.blue-text {
  color: #515256;
}

.btn-bg {
  background: #2677d9;
}

.input-bg {
  background: linear-gradient(90deg, #4891c0 0%, #5bd2d6 100%);
}
.labels {
  /* background-color: #26C8BE; */
  color: #17bb94;

  /* font-family: sans-serif; */
  border-radius: 0.3rem;
  /* cursor: pointer; */
  /* margin-top: 1rem; */
}
.labels:active {
  color: #16aa86;
}
.label {
  /* background-color: #26C8BE; */
  color: #2677d9;

  /* font-family: sans-serif; */
  border-radius: 0.3rem;
  /* cursor: pointer; */
  /* margin-top: 1rem; */
}
.label:active {
  color: #226ac1;
}
input[type="file"]::file-selector-button {
  visibility: hidden;
}
.feedback {
  /* display: flex; */

  width: 100%;
  background-color: #01a35f;
  min-height: 52px;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.12);
  border-bottom-style: solid;
  color: white;
  /* font-size: 16px; */
  border-radius: 5px;
  margin-bottom: 5px;
}

.submit {
  color: #c69516;
}
.submit:active {
  color: #b78811;
}
.dtqzBx div:first-child {
  white-space: normal !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.kEIRkz div:first-child {
  white-space: normal !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.float {
  position: fixed;
  bottom: 50px;
  right: 10px;
  text-align: center;
  padding: 10px 20px 10px 20px;
  box-shadow: 2px 2px 3px #999;
}

.fix {
  text-align: center;
  padding: 10px 20px 10px 20px;
  box-shadow: 2px 2px 3px #999;
}
.welcome {
  border-width: 0px 3px 0px 3px;
  padding-top: 10px;
  border-style: solid;
  text-align: center;
  justify-content: center;
  border-color: #ffa1ec;
  /* background: #FFA1EC; */
  height: 53px;
  width: 256px;
  left: 555px;
  top: 390px;
  border-radius: 6px;
}
.cards {
  height: 330px;
  width: 300px;
  left: 137px;
  top: 1153px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.12);
}
.fishes {
  position: relative;
  top: 0;
  left: 0;
}
.fish {
  position: absolute;
  top: 140px;
  left: 150px;
}
@media only screen and (max-width: 400px) {
  .fish {
    position: absolute;
    top: 40px;
    left: 50px;
  }
  .chat {
    background: "#F4F4F4";
  }
  .fish {
    position: absolute;
    top: 40px;
    left: 50px;
  }
}
@media only screen and (min-width: 600px) {
  .Img {
    height: 250px;
  }
}

.bg {
  object-fit: cover;
  position: absolute;

  z-index: -1;
}
.banner-title {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 0px 40px;
  /* text-align: center; */
  box-sizing: border-box;
}
.videoembed {
  position: absolute;
  top: 0;
  /*z-index: -99; */
  width: 100%;

  /* height: 100%; */
  /* background: #333; */
}
.banner {
  position: relative;
  /* padding-bottom: 40%; */
  width: 100%;
  overflow: hidden;
}
.label {
  display: inline-block;
  border-radius: 0.3rem;
  border: 1 px solid;
  cursor: pointer;
  margin-top: 1rem;
}
/* .chat{
  background: "#FFFFFF"
} */
textarea:focus,
input:focus {
  outline: 0;
}
textarea:-moz-placeholder, /* Firefox 18- */
textarea::-moz-placeholder, /* Firefox 19+ */
textarea:-ms-input-placeholder, /* IE 10+ */
textarea::-webkit-input-placeholder, /* Webkit based */
textarea::placeholder {
  /* Modern browsers */
  /* color: #000;   */
}
